.c-RootView {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  height: 100%;
  padding: 10px;
  overflow: hidden;

  > .content-container {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    height: 100%;
    overflow-y: auto;

    > .header {
      text-align: center;
      margin-bottom: 30px;
    }
  }
}
