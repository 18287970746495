.c-Content {
  display: flex;
  flex-direction: column;
  flex: 1 1 0px;
  padding: 32px;
  background-color: #FFFFFF;
  overflow: hidden;

  >.drilldown-content-gate {
    display: flex;
    flex-direction: column;
    flex: 1 1 0px;
    overflow: hidden;
  }

  .content-body {
    display: flex;
    flex: 1 1 0px;
    overflow: hidden;

    .tabs-container {
      display: flex;
      flex-direction: column;
      flex: 1 0 auto;
      width: 100%;
      margin-top: 40px;

      .manipulation-buttons {
        display: flex;
        flex: 0 0 auto;
        justify-content: flex-end;
        width: 100%;
      }

      .tabs-selectors-container {
        display: flex;
        flex: 0 0 auto;
        z-index: 100;
        overflow-x: auto;

        .tab-selector {
          margin-right: 10px;
          padding: 12px 18px;
          font-size: 15px;
          line-height: 20px;
          color: #B6BEC2;
          background-color: #F9FAFB;
          border: solid 1px #E2E7E9;
          border-radius: 5px 5px 0 0;
          min-width: max-content;
          cursor: pointer;

          &.active {
            color: #59A3CF;
            border-top: solid 4px #59A3CF;
            border-bottom: #FFFFFF;
            background-color: #FFFFFF;
          }

          &.not-active {
            .tab-text {
              margin-top: 3px;
            }
          }
        }
      }
    }

    .tab-content-container {
      display: flex;
      flex: 1 1 0px;
      overflow: hidden;
      border: solid 1px #E2E7E9;
      margin-top: -1px;
    }
  }
}
