.c-InventoryView {
  display: flex;
  flex: 1 1 100%;

  .c-Content {
    padding: 0;

    .tabs-container {
      margin-top: 0;
    }
  }
}
