@import '@clientCommon/style-variables-colors.scss';

.c-InventoryTable {
  .header-cell {
    margin-right: 4px;
  }

  .drug-name {
    padding-right: 10px;
  }

  .NDC,
  .quantity {
    font-weight: 600;
    margin-right: 2px;
  }

  .quantity {
    margin-left: 8px;
  }

  .numbered-item {
    text-align: right;
    padding-right: 10px;
  }
}

.threshold-in-bounds {
  color: $green;
}

.threshold-out-of-bounds {
  color: $dark-red;
}
