.c-KioskStorage {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  padding: 10px;
  overflow: hidden;
  position: relative;

  .c-KioskSlotsTable {
    .vacant,
    .purgeable,
    .total {
      text-align: right;
    }
  }
}
