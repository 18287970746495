@import '../../styles/colors.scss';

.c-SearchInputView {
  display: flex;
  flex-direction: row;
  position: relative;
  flex: 0 0 auto;

  img.search-icon {
    position: absolute;
    width: 16px;
    height: 16px;
    left: 12px;
    top: 10px;
  }

  input {
    background-color: $spec-white;
    width: 100%;
    font-size: 15px;
    line-height: 20px;
    padding: 7px 12px;
    padding-left: 36px;
    color: #b6bec2;
    background-color: #f9fafb;
    border: solid 1px #e2e7e9;
    border-radius: 2px;
    outline: none;
    width: 100%;
  }
}
