.SmartTableContent {
  min-width: 100%;
  background-color: white;

  .content-cell {
    box-sizing: border-box;
    display: flex;
    flex: 0 1 auto;
    justify-content: flex-end;
    align-items: center;
    padding: 10px 0px;
    vertical-align: middle;
    white-space: normal;

    &:first-of-type {
      justify-content: flex-start;
    }
  }

  .select-unavailable-caption {
    min-width: 10%;
  }

  .checkbox-container {
    display: flex;
    flex: 1 1 auto;
    justify-content: center;
    align-items: center;

    .checkbox {
      vertical-align: middle;
      width: 19px;
      height: 19px;
    }
  }
}

.content-row {
  &--withClickable {
    cursor: pointer;
    &:hover {
      background-color: #d6f0ff;
      .content-cell-button {
        visibility: visible;
      }
    }
  }

  &-active {
    background-color: #d6f0ff;
  }
}
