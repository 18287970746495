@import '../../../styles/colors.scss';

h1.h1 {
  font-size: 36px;
  line-height: 40px;
  color: $main-blue;
  margin: 0px;

  &.neutral {
    color: $text-dark-grey;
  }
}
