@import '@clientCommon/style-variables-colors.scss';

.c-NoKiosk {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  text-align: center;

  > .no-kiosk-text {
    color: $dark-red;
  }
}
