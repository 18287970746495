.SmartTableHeader {
  display: flex;
  min-width: 100%;
  padding-left: 15px;
  white-space: nowrap;

  .header-cell {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    box-sizing: border-box;
    padding: 10px 0px;
    margin-right: 4px;

    &:first-of-type {
      justify-content: flex-start;
    }

    .title {
      color: #999;
      margin-right: 3px;
    }

    .icon {
      width: 10px;
      height: 10px;
      cursor: pointer;
    }
  }
}

